import { useState, useEffect, useCallback } from "react";
import {
	// updateDocData,
	getDocumentsData,
} from "./config/firebaseCRUD";
// import moment from "moment";
import { Checkbox } from "@mui/material";

export const Grocery = () => {
	const [enableList, setEnableList] = useState(false);
	const setCansStateData = useCallback((cans, month) => {
		const filteredData = cans.filter((data) => data.id === "watercans")[0][
			month
		];
		// setCansData(sortBy(filteredData, ["date"]));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getCansData = useCallback(() => {
		getDocumentsData("housedairy")
			.then((res) => {
				console.log(res);
				// setCansStateData(res, moment().format("MMMM"));
			})
			.catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		getCansData();
	}, [getCansData]);
	return (
		<div className="container">
			<label>Create New List</label>
			<Checkbox
				onChange={(e) => setEnableList(e.target.checked)}
				name="enableList"
			/>
			<form>
				<div></div>
			</form>
		</div>
	);
};

export default Grocery;

import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { getDocumentsData } from "./config/firebaseCRUD";



export const App = () => {
  const [totalData, setTotalData] = useState([]);

  const getTotalCount = (monthlyCans) => {
    let count = 0
    if (monthlyCans && monthlyCans.length) {
      monthlyCans.forEach((can) => count += can.cans)
    }
    return count
  }

  const getCansData = useCallback(() => {
    getDocumentsData("housedairy").then((res) => {
      setTotalData(res)
    }
    ).catch(err => console.log(err))
  }, []);

  useEffect(() => {
    getCansData();
  }, [getCansData])

  const getTotalCansData = () => totalData.filter(data => data.id === "watercans")[0] || {}

  return <>
    <div >
      {
        totalData.length > 0 && <div>
          <p>Current Month Water Cans : {getTotalCount(getTotalCansData()[moment().format("MMMM")])}</p>
          <p>Last Month Total Cans: {getTotalCount(getTotalCansData()[moment().subtract(1, "months").format("MMMM")])}</p>
        </div>
      }

    </div>
  </>
}

export default App;
import {
  createBrowserRouter,
} from "react-router-dom";
import WaterCans from "./WaterCans";
import { getErrorPage } from './ErrorPage';
import App from './App';
import AppWrapper from "./AppWrapper";
import Grocery from "./Grocery";
import DepositCalculator from "./DepositCaluclators";
import MedicationTracker from "./AnuMedication";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppWrapper Component={<App />} />,
  },
  {
    path: "/waterCans",
    element: <AppWrapper Component={<WaterCans />} />
  },
  {
    path: "/grocery",
    element: <AppWrapper Component={<Grocery />} />
  },
  {
    path: "/depositCalculator",
    element: <AppWrapper Component={<DepositCalculator />} />
  },
  {
    path: "/anuMedication",
    element: <AppWrapper Component={<MedicationTracker />} />
  },
  {
    path: "*",
    element: <AppWrapper Component={getErrorPage("Page Not Found!!!")} />
  }
]);
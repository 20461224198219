import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemButton } from "@mui/material";
import { Dashboard, LocalDrink, LocalGroceryStore, MedicalInformationOutlined, Menu } from "@mui/icons-material";
import { useNavigate } from "react-router";

export const AppWrapper = ({ Component }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  return <>
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <Menu onClick={() => setMenuOpen(!menuOpen)} />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          House Dairy
        </Typography>
      </Toolbar>
    </AppBar>

    <React.Fragment key={"right"}>
      <Drawer
        anchor={"left"}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <List>
          <ListItem onClick={() => {
            navigate("/")
            setMenuOpen(false)

          }}>
            <ListItemButton>
              <Dashboard /> Dashboard
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => {
            navigate("/waterCans")
            setMenuOpen(false)

          }}>
            <ListItemButton>
              <LocalDrink /> Water Cans
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => {
            navigate("/grocery")
            setMenuOpen(false)
          }}>
            <ListItemButton>
              <LocalGroceryStore /> Grocery
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => {
            navigate("/depositCalculator")
            setMenuOpen(false)
          }}>
            <ListItemButton>
              <LocalGroceryStore /> Assets Calculator
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => {
            navigate("/anuMedication")
            setMenuOpen(false)
          }}>
            <ListItemButton>
              <MedicalInformationOutlined /> Anu Medication
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
    {Component}
  </>
}

export default AppWrapper;
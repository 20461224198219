import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { arrayUnion } from "firebase/firestore";
import CansPreview from './CansPreview';
import { updateDocData, getDocumentsData } from "./config/firebaseCRUD";
import sortBy from "lodash/sortBy";

const initialState = {
  count: 0,
  date: moment(new Date()).format('YYYY-MM-DD')
};

export const WaterCans = () => {
  const [count, updateCount] = useState(initialState.count)
  const [selectedDate, updateSelectedDate] = useState(initialState.date);
  const [selectedMonth, updateSelectedMonth] = useState(moment().format("MMMM"))

  const [cansData, setCansData] = useState([]);
  const [totalData, setTotalData] = useState([]);

  const setCansStateData = useCallback((cans, month) => {
    const filteredData = cans.filter(data => data.id === "watercans")[0][month];
    setCansData(sortBy(filteredData, ["date"]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCansData = useCallback(() => {
    getDocumentsData("housedairy").then((res) => {
      setTotalData(res)
      setCansStateData(res, moment().format("MMMM"))
    }
    ).catch(err => console.log(err))
  }, [setCansStateData]);

  useEffect(() => {
    getCansData();
  }, [getCansData])

  const month = moment(new Date(selectedDate)).format("MMMM");

  return (
    <div className="App">
      <div>
        <h3>Welcome to Home Dairy</h3>
        <div>
          Please add your necessary details
        </div>
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ margin: "1rem" }}>
            <input type='date' value={selectedDate} onChange={(e) => {
              updateSelectedDate(moment(new Date(e.target.value)).format('YYYY-MM-DD'))
            }} /></div>
          <div style={{ display: "flex", margin: "1rem" }}>
            <button onClick={() => updateCount(count - 1)} disabled={count <= 0}>-</button>
            <div style={{ display: "lightgray", marginLeft: "3px", marginRight: '3px' }}>{count}</div>
            <button onClick={() => updateCount(count + 1)}>+</button>

          </div>
        </div>
      </div>
      <div>
        <button disabled={count <= 0} onClick={async () => {
          updateDocData({
            colName: "housedairy",
            docId: "watercans",
            data: {
              [month]: arrayUnion({
                date: selectedDate,
                cans: count
              })
            },
            onSuccess: getCansData
          }).then((res) => {
            updateCount(initialState.count);
            updateSelectedDate(initialState.date)
          })
        }}>Submit</button>
      </div>
      <CansPreview cansData={cansData} selectedMonth={selectedMonth} onMonthUpdate={
        (month) => {
          updateSelectedMonth(month);
          setCansStateData(totalData, month)
        }} />
    </div>
  );
};

export default WaterCans;
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbo6vjOimcG69XjYv_N-w57DmzQAmk7XE",
  authDomain: "housedairy-c1167.firebaseapp.com",
  projectId: "housedairy-c1167",
  storageBucket: "housedairy-c1167.appspot.com",
  messagingSenderId: "353727081367",
  appId: "1:353727081367:web:be3d4ae9cee20b5711b2d2",
  measurementId: "G-RPMPDL1EV8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const firestoreDb = getFirestore(app);
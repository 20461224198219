import { collection, query, getDocs, getDoc, doc, updateDoc } from "firebase/firestore";
import { firestoreDb as firestore } from "./firebaseConfig";

export const getQueryRef = async (ref, criteria) => {
  if (criteria) {
    return await query(collection(firestore, ref), ...criteria);

  } else {
    return await query(collection(firestore, ref));

  }
};

export const getDocumentsData = (colName, options = {}) => {
  const { query, callBack } = options;

  let collectionRef;

  collectionRef = getQueryRef(colName, query);

  return collectionRef.then((colRef) => {

    return getDocs(colRef).then((docsRes) => {
      if (callBack) {
        callBack()
      }
      console.log("In Document : ", docsRes.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
      return docsRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    })
  })

}

export const getDocData = (collection, id) => {
  const docRef = doc(firestore, collection, id)
  let docData;
  docData = getDoc(docRef).then((docSnap) => {
    return { id: docSnap.id, ...docSnap.data() }
  })
  return docData;
}

export const updateDocData = async ({ colName, docId, data, onSuccess = () => { }, onError = () => { } }) => {
  const docRef = doc(firestore, colName, docId);

  await updateDoc(docRef, data, { merge: true })
    .then((res) => {
      console.log("Update DOC Res", res)
      console.log(" Document has been updated successfully");
      onSuccess()
      return true;
    })
    .catch((error) => {
      console.log(error);
      onError()
    });
}
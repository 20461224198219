import moment from "moment";

export const CansPreview = ({ cansData = [], selectedMonth, onMonthUpdate }) => {
  const getTotalCount = () => {
    let count = 0
    cansData.forEach((can) => count += can.cans)
    return count
  }
  const months = ["January", "Febraury", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  return <>
    <div>
      <h4>Select Month to get the Cans Details</h4>
      <select onChange={(e) => { onMonthUpdate(e.target.value) }}
        defaultValue={selectedMonth} >
        {months.map((mnth) => <option value={mnth} key={`${mnth}`}>{mnth}</option>)}
      </select>
    </div >
    <div>
      <h1>Total Water Cans for {moment().format('MMMM')} - {getTotalCount()}</h1>
      <div className="cans-previewTable">
        <table className="tableClass">
        <thead>
          <tr>
            <th>Date</th>
            <th>Cans</th>
          </tr>
        </thead>
        <tbody style={{ width: "50%" }}>
          {
            cansData.map((can, i) => {
              return <>
                <tr style={{ background: can.original ? "green" : "white" }}>
                  <td>{can.date}</td>
                  <td>{can.cans}</td>
                </tr>
              </>
            })
          }
        </tbody>
      </table>
      </div>
    </div >
  </>

};

export default CansPreview; 
import React, { useState } from "react";
import "./DepositCalculator.css"; // Import your CSS file for styles

// Loan EMI Calculator EMI = [P x R x (1+R) ^N]/ [(1+R) ^ (N-1)],
// FD Calculator principal * Math.pow(1 + interestRateInDecimal / 4, 4 * time);

const DepositCalculator = () => {
	const [depositType, setDepositType] = useState("fixed");
	const [principal, setPrincipal] = useState("");
	const [rate, setRate] = useState("");
	const [time, setTime] = useState("");
	const [monthlyDeposit, setMonthlyDeposit] = useState("");
	const [result, setResult] = useState(null);

	const calculateInterest = () => {
		let calculatedInterest;
		const interestRateInDecimal = rate / 100;
		if (depositType === "fixed") {
			calculatedInterest =
				principal * Math.pow(1 + interestRateInDecimal / 4, 4 * time);
			setResult(calculatedInterest);
		} else if (depositType === "recurring") {
			const n = 4; // convert time to months
			const r = rate / 100; // convert annual rate to monthly
			const tenureInMonths = time * 12;
			let rdCalculatedInterest = 0;
			for (let i = 1; i <= tenureInMonths; i++) {
				rdCalculatedInterest +=
					monthlyDeposit * Math.pow(1 + r / n, n * (i / 12));
			}
			setResult(rdCalculatedInterest);
			return rdCalculatedInterest;
		}
	};

	return (
		<div className="calculator-container">
			<h1>Deposit Calculator</h1>
			<div>
				<label>
					Select Deposit Type:
					<select
						value={depositType}
						onChange={(e) => setDepositType(e.target.value)}
						className="input-field"
					>
						<option value="fixed">Fixed Deposit</option>
						<option value="recurring">Recurring Deposit</option>
					</select>
				</label>
			</div>
			{depositType === "fixed" && (
				<div>
					<label>
						Principal Amount:
						<input
							type="number"
							value={principal}
							onChange={(e) => setPrincipal(parseInt(e.target.value))}
							className="input-field"
						/>
					</label>
				</div>
			)}

			<div>
				<label>
					Interest Rate (in %):
					<input
						type="number"
						value={rate}
						onChange={(e) => setRate(e.target.value)}
						className="input-field"
					/>
				</label>
			</div>
			<div>
				<label>
					Time (in years):
					<input
						type="number"
						value={time}
						onChange={(e) => setTime(e.target.value)}
						className="input-field"
					/>
				</label>
			</div>
			{depositType === "recurring" && (
				<div>
					<label>
						Monthly Deposit:
						<input
							type="number"
							value={monthlyDeposit}
							onChange={(e) => setMonthlyDeposit(e.target.value)}
							className="input-field"
						/>
					</label>
				</div>
			)}
			<button onClick={calculateInterest} className="calculate-button">
				Calculate
			</button>
			{result !== null && (
				<>
					<div>
						<h3>Calculated Maturity Amount:</h3>
						<p className="result">{result.toFixed(0)}</p>
					</div>
					<div>
						<h3>Principal Amount:</h3>
						<p className="result">{monthlyDeposit * time * 12}</p>
						<h3>Total Interest:</h3>
						<p className="result">
							{result.toFixed(0) - monthlyDeposit * time * 12}
						</p>
					</div>
				</>
			)}
		</div>
	);
};

export default DepositCalculator;

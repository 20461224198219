import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { getDocumentsData, updateDocData } from "./config/firebaseCRUD";
import { arrayUnion } from "firebase/firestore";
import { useEffect } from "react";
import { useCallback } from "react";

export const MedicationTracker = () => {
	// const [medicationInput, setMedicationInput] = useState([]);
	// const [dailyDosage, checkDailyDosage] = useState({});
	// const [openDialog, setOpenDialog] = useState(false);
	const [urineMeasuring, setUrineMeasuring] = useState({});
	const [urineData, setUrineData] = useState([]);

	const getUrineData = useCallback(() => {
		getDocumentsData("housedairy")
			.then((res) => {
				const data = res.filter((data) => data.id === "urineMeasurement")[0]
					.data;
				setUrineData(data);
			})
			.catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		getUrineData();
	}, [getUrineData]);

	return (
		// <div className="container">
		// 	<Dialog open={openDialog}>
		// 		<DialogTitle>Add Prescription Tablets.</DialogTitle>
		// 		<DialogContent>
		// 			<div>
		// 				<div style={{ display: "flex" }}>
		// 					<input type="text" onChange={(e) => setMedicationInput()} />
		// 				</div>
		// 				<div>
		// 					<Button
		// 						size="small"
		// 						variant="outlined"
		// 						onClick={() => {
		// 							setOpenDialog(false);
		// 						}}
		// 					>
		// 						Add
		// 					</Button>
		// 				</div>
		// 			</div>
		// 		</DialogContent>
		// 	</Dialog>
		// 	<div>Anu Medication Records:</div>
		// 	<div>
		// 		<Button
		// 			onClick={() => {
		// 				setOpenDialog(true);
		// 			}}
		// 			variant="outlined"
		// 		>
		// 			Add Medication Prescription:
		// 		</Button>
		// 	</div>
		// </div>
		<div>
			<Grid style={{ padding: "1rem" }}>
				<div>
					<Typography variant="h5">Urine Measuring</Typography>
				</div>

				<div>
					<input
						type="number"
						onChange={(e) => {
							e.preventDefault();
							setUrineMeasuring({
								date: moment().format("lll"),
								volume: e.target.value,
							});
						}}
					></input>
					<Button
						size="small"
						variant="outlined"
						style={{ marginLeft: "1rem" }}
						onClick={() => {
							updateDocData({
								colName: "housedairy",
								docId: "urineMeasurement",
								data: {
									data: arrayUnion(urineMeasuring),
								},
								onSuccess: getUrineData,
							}).then((res) => {});
						}}
					>
						Add Value
					</Button>
				</div>
			</Grid>
			<Grid>
				<ul>
					{urineData &&
						urineData.map((data) => (
							<li>
								<span>{data.date} ----</span>
								<span>{data.volume} ML Appx.</span>
							</li>
						))}
				</ul>
			</Grid>
		</div>
	);
};
export default MedicationTracker;
